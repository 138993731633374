//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey:'AIzaSyArSkFeuMQ7GDDVlzvLi-5ElsYo-MUdxv8',
  authDomain:'opopdb-d64f9.firebaseapp.com',
  projectId:'opopdb-d64f9',
  storageBucket:'opopdb-d64f9.firebasestorage.app',
  messagingSenderId:'225354381601',
  appId: '1:225354381601:web:50c7a9d77208b0678c2660',
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
